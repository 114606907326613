import { useState } from 'react';
import { CustomerStateType } from '../types/global-state-data.type';
import { CustomerInfo, CustomerAddress } from '../types';

const getInitialCustomerInfo = (): CustomerInfo => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const custInfo = localStorage.getItem('CustomerInfo');
    if (custInfo) {
      return JSON.parse(custInfo);
    }
  }
  return {};
};

const getInitialCustomerAddress = (): CustomerAddress => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const custAddress = localStorage.getItem('CustomerAddress');
    if (custAddress) {
      return JSON.parse(custAddress);
    }
  }
  return {};
};

const useCustomerState = (): CustomerStateType => {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(
    getInitialCustomerInfo()
  );
  const [customerAddress, setCustomerAddress] = useState<CustomerAddress>(
    getInitialCustomerAddress()
  );

  const updateCustomerInfo = (cInfo: CustomerInfo) => {
    const updatedCustomerInfo: CustomerInfo = {
      firstName: cInfo.firstName,
      lastName: cInfo.lastName,
      phoneNumber: cInfo.phoneNumber,
      phoneNumberAreaCode: cInfo.phoneNumberAreaCode,
      email: cInfo.email
    };
    setCustomerInfo(updatedCustomerInfo);
    localStorage.setItem('CustomerInfo', JSON.stringify(updatedCustomerInfo));
  };

  const updateCustomerAddress = (cAddress: CustomerAddress) => {
    const updatedCustomerAddress: CustomerAddress = {
      address: cAddress.address,
      city: cAddress.city,
      postalCode: cAddress.postalCode,
      country: cAddress.country
    };
    setCustomerAddress(updatedCustomerAddress);
    localStorage.setItem(
      'CustomerAddress',
      JSON.stringify(updatedCustomerAddress)
    );
  };

  const clearCustomer = () => {
    setCustomerInfo({});
    setCustomerAddress({});
    localStorage.removeItem('CustomerInfo');
    localStorage.removeItem('CustomerAddress');
  };

  return {
    customerInfo,
    customerAddress,
    updateCustomerInfo,
    updateCustomerAddress,
    clearCustomer
  };
};

export default useCustomerState;
