import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { StripeBasketStatus, StripeCheckoutResult } from '../types';

export const postStripeCheckout = async (
  basket: any,
  tableNumber: string
): Promise<AxiosResponse<StripeCheckoutResult>> => {
  try {
    const response = await api.post(
      `PaymentProvider/stripe/checkout?tableNumber=${tableNumber}`,
      basket
    );
    if (response.status == 200 || response.status == 204) {
      return response;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export const getStripeCheckoutStatus = async (
  sessionId: string
): Promise<AxiosResponse<StripeBasketStatus>> => {
  try {
    const response = await api.get(
      `PaymentProvider/stripe/checkout?sessionId=${sessionId}`
    );
    if (response.status == 200 || response.status == 204) {
      return response;
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};
