import { useEffect, useState } from 'react';
import {
  createBasket,
  getBasketById,
  patchAddItemsByBasketId,
  patchRemoveAllItemsByBasketId,
  patchRemoveItemsById,
  patchUpdateItemsByBasketId
} from '../services/basket.service';
import {
  BasketItem,
  BasketStateType,
  BasketType,
  GridItemProps,
  GridItemType,
  OrderCustomization
} from '../types';
import {
  getNamedLocalStorage,
  removeNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';

const emptyBasket: BasketType = {
  externalUid: '',
  totalPrice: 0,
  totalQuantity: 0,
  basketItems: []
};

const calculateTotal = (
  basketItems: BasketItem[]
): { totalPrice: number; totalQuantity: number } => {
  let totalPrice = 0;
  let totalQuantity = 0;
  basketItems.forEach(item => {
    if (item.quantity > 0) {
      const itemPrice = item.price || 0;

      totalPrice += itemPrice * item.quantity;
      totalQuantity += item.quantity;
    }
  });
  return { totalPrice, totalQuantity };
};

const useBasketState = (): BasketStateType => {
  const [basketLoading, setBasketLoading] = useState<boolean>(true);
  const [basket, setBasket] = useState<BasketType>(emptyBasket);
  const [basketId, setBasketId] = useState<string>('');

  const getInitialBasket = async (): Promise<void> => {
    const basketJson = getNamedLocalStorage('basket');
    const bask = basketJson ? JSON.parse(basketJson) : {};
    let result;
    setBasketLoading(true);

    if (bask.externalUid) {
      const { data } = await getBasketById(bask.externalUid);
      result = data;

      if (result.completedTime != null) {
        result = await createBasket();
      }
    } else {
      result = await createBasket();
    }

    setBasketId(result.externalUid as string);
    setNamedLocalStorage('basket', JSON.stringify(result));
    setBasket(result);
    setBasketLoading(false);
  };

  const getBasketItems = (
    referenceId?: number,
    type?: GridItemType
  ): BasketItem[] => {
    return basket.basketItems.filter(
      (basketItem: BasketItem) =>
        basketItem.referenceId === referenceId && basketItem.type === type
    );
  };

  const updateBasket = (updatedBasketItems: BasketType) => {
    const { totalPrice, totalQuantity } = calculateTotal(
      updatedBasketItems.basketItems
    );

    const updatedBasket = {
      externalUid: basket.externalUid,
      basketItems: updatedBasketItems.basketItems,
      totalPrice,
      totalQuantity
    };
    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const updateBasketId = (id: string) => {
    const newBasket = { ...basket, externalUid: id };
    setBasket(newBasket);
    setNamedLocalStorage('basket', JSON.stringify(newBasket));
  };

  const updateBasketItem = async (
    id: number,
    quantity: number,
    customization: OrderCustomization = {}
  ) => {
    const basketItemToUpdate = basket.basketItems.find(
      item => item.id === id
    ) as BasketItem;
    const newBasketItem = {
      ...basketItemToUpdate,
      ...customization,
      quantity
    };
    setBasketLoading(true);

    const basketJson = getNamedLocalStorage('basket');
    const bask = basketJson ? JSON.parse(basketJson) : {};

    const newBasket = await patchUpdateItemsByBasketId(
      bask.externalUid,
      newBasketItem
    );
    updateBasket(newBasket);
    setBasketLoading(false);
  };

  const addBasketItem = async (
    newItem: GridItemProps,
    customization: OrderCustomization = {},
    newQuantity: number
  ) => {
    const identicalItemFound = newItem.value.isCustomizable
      ? basket.basketItems.find(
          i =>
            i.referenceId === newItem.referenceId &&
            i.type === newItem.type &&
            JSON.stringify(i.customization) === JSON.stringify(customization)
        )
      : basket.basketItems.find(
          i => i.referenceId === newItem.referenceId && i.type === newItem.type
        );
    if (identicalItemFound) {
      updateBasketItem(
        identicalItemFound.id,
        (identicalItemFound.quantity = newQuantity),
        customization
      );
    } else {
      setBasketLoading(true);

      const basketJson = getNamedLocalStorage('basket');
      const bask = basketJson ? JSON.parse(basketJson) : {};

      const newBasket = await patchAddItemsByBasketId(bask.externalUid, {
        id: newItem.id,
        referenceId: newItem.referenceId || 0,
        type: newItem.type,
        label: newItem.label,
        quantity: newQuantity || 1,
        ...customization
      });
      setBasket(newBasket);
      setNamedLocalStorage('basket', JSON.stringify(newBasket));
      setBasketLoading(false);
    }
  };

  const removeBasketItem = async (id: number) => {
    setBasketLoading(true);
    const newBasket = await patchRemoveItemsById(basketId, id);
    updateBasket(newBasket);
    setBasketLoading(false);
  };

  const clearBasket = async (clearBasketId: boolean = false) => {
    setBasketLoading(true);
    if (clearBasketId) {
      await removeNamedLocalStorage('basket');
      await removeNamedLocalStorage('CustomerInfo');
      await setNamedLocalStorage('basket', JSON.stringify(emptyBasket));
      const newBasket = await createBasket();
      setBasketId('');
      setBasket(newBasket);
    } else {
      const newBasket = await patchRemoveAllItemsByBasketId(basketId);
      setBasket(newBasket);
    }
    setBasketLoading(false);
  };

  useEffect(() => {
    getInitialBasket();
  }, []);

  return {
    basket,
    basketLoading,
    addBasketItem,
    updateBasketItem,
    getBasketItems,
    removeBasketItem,
    clearBasket,
    updateBasket,
    updateBasketId
  };
};

export default useBasketState;
