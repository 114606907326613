import { createContext } from 'react';
import { GlobalContextType } from '../types';

const GlobalContext = createContext<GlobalContextType>({
  basket: { basketItems: [], externalUid: '', totalPrice: 0, totalQuantity: 0 },
  composerTiles: [],
  currencyCode: '',
  imageUrl: '',
  loading: false,
  error: '',
  customerInfo: {},
  customerAddress: {},
  basketLoading: false,
  table: { id: 0, name: '', description: '' },
  defaultLocale: 'en-gb',

  getComposerTiles: () => {},

  clearCustomer: () => {},
  updateCustomerInfo: () => {},
  updateCustomerAddress: () => {},

  addBasketItem: () => {},
  clearBasket: () => Promise.resolve(),
  getBasketItems: () => {},
  removeBasketItem: () => {},
  updateBasket: () => {},
  updateBasketId: () => {},
  updateBasketItem: () => {}
});

export default GlobalContext;
