import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { Toaster } from '@k3imagine/self-serve-components';
import { ErrorScreen, Grid, Layout, StartScreen } from '../components';
import '../locales/i18n';
import useGlobalState from '../state/useGlobalState';
import useBasketState from '../state/useBasketState';
import GlobalContext from '../state/GlobalContext';
import useCustomerState from '../state/useCustomerState';

type RouteProps = {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
} & RouteComponentProps;

const Route = ({
  component: Component,
  path,
  children,
  ...rest
}: RouteProps) => {
  return <Component {...rest}>{children}</Component>;
};

// Working URL parameters for Mock API
// tableId shopId tenantId
// 0001 0001 02305 => localhosturl/?GJFNVL
// 0002 0001 02305 => localhosturl/?X2T4TD
// 0001 0002 02305 => localhosturl/?GJHT1D

const App = () => {
  const globalState = {
    ...useGlobalState(),
    ...useBasketState(),
    ...useCustomerState()
  };

  const GlobalStyles = createGlobalStyle`
    @import url(${globalState.visualProfileFonts?.stylesheetUrl});
    * {
      font-family: ${globalState.visualProfileFonts?.name ||
        'Source Sans Pro'}, 'Source Sans Pro';
    }
    body {
     margin: 0px;
    }
`;

  return (
    <GlobalContext.Provider value={globalState}>
      <Helmet>
        <title>Top</title>
      </Helmet>
      <GlobalStyles />
      <Router>
        <Route path="/" component={StartScreen}>
          <Route path="/" component={Layout}>
            <Route path="/groups/*" component={Grid} />
          </Route>
        </Route>
        <Route path="/error" component={ErrorScreen} />
      </Router>
      <Toaster />
    </GlobalContext.Provider>
  );
};

export default App;
