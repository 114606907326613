import React, { useContext, useState, useEffect } from 'react';
import { Loader } from '@k3imagine/self-serve-components';

import * as S from './CardPayment.styles';
import loadScript from '../../../../../../../../utils/loadScript';
import GlobalContext from '../../../../../../../../state/GlobalContext';
import { getGlobalPayHpp } from '../../../../../../../../services/payment.service';
import { PaymentStatus } from '../../../../../../../../types';

export type CardPaymentProps = {
  totalAmount?: number;
  goNext: Function;
  goBack?: Function;
  finalizeSale: Function;
};

const CardPayment = ({
  goNext,
  totalAmount,
  finalizeSale
}: CardPaymentProps) => {
  const [iFrameDataReady, setIframeDataReady] = useState<boolean>(false);
  const [iFrameHeight, setIframeHeight] = useState<string>('600px');
  const [hppLoaded] = loadScript('/rxp-js.js');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currencyCode, customerInfo } = useContext(GlobalContext);
  const { email } = customerInfo;

  const requestData = {
    amount: totalAmount,
    currencyCode,
    customer: {
      email: email || '000@000.is',
      phoneMobile: '44|00000000'
    },
    billingAddress: {
      streetAddress1: '000',
      streetAddress2: '000',
      streetAddress3: '000',
      city: '000',
      postalCode: '000',
      country: '826'
    }
  };

  const getGeneratedHpp = async () => {
    const globalPayHppResponse = await getGlobalPayHpp(requestData);
    if (globalPayHppResponse.status >= 400) {
      goNext({ paymentStatus: PaymentStatus.Failure });
    } else {
      return globalPayHppResponse.data;
    }
  };

  const receiveMessage = (messageEvent: any) => {
    setIframeDataReady(true);
    try {
      const globalPayMessage = JSON.parse(messageEvent.data);
      if (globalPayMessage.iframe) {
        setIframeHeight(globalPayMessage.iframe.height);
      } else {
        finalizeSale({ globalPayResponse: globalPayMessage });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(messageEvent.data);
      goNext({ paymentStatus: PaymentStatus.Failure });
    }
  };

  useEffect(() => {
    if (hppLoaded) {
      (async () => {
        setIsLoading(true);
        const hppData = await getGeneratedHpp();
        if (hppData) {
          RealexHpp.setHppUrl(hppData.hppUrl);
          RealexHpp.embedded.init(
            'gbPayButton',
            'gbPayIframe',
            '',
            hppData.hppPayload
          );

          setIsLoading(false);
          window.addEventListener('message', receiveMessage, false);

          const payButton = document.getElementById('gbPayButton');
          if (payButton) {
            payButton.click();
          }
        }
      })();

      return () => {
        // Must clean-up event listener before next payment is made.
        window.removeEventListener('message', receiveMessage);
      };
    }
  }, [hppLoaded]);

  return (
    <>
      {(!iFrameDataReady || isLoading) && (
        <S.TopMargin>
          <Loader width={200} color="black" />
        </S.TopMargin>
      )}
      <S.IFrameContainer hidden={!iFrameDataReady || isLoading}>
        <S.GlobalPayButton id="gbPayButton">OB Pay</S.GlobalPayButton>
        <S.GlobalPayIframe id="gbPayIframe" height={iFrameHeight} />
      </S.IFrameContainer>
    </>
  );
};

export default CardPayment;
