import React, { useContext, useState, useEffect } from 'react';
import { Loader, PaymentButton } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import * as S from './ValitorPayment.styles';
import {
  postSale,
  getValitorSignature
} from '../../../../../../services/payment.service';
import loadScript from '../../../../../../utils/loadScript';
import GlobalContext from '../../../../../../state/GlobalContext';
import {
  PaymentStatus,
  ValitorSignatureResponse
} from '../../../../../../types';
import { DisableScreenClick } from '../../Payment.styles';

type PaymentProps = {
  goNext: Function;
  totalAmount: number;
};

declare const window: any;

const ValitorPayment = ({ goNext, totalAmount }: PaymentProps) => {
  const { paymentProvider, currencyCode, basket, customerInfo } = useContext(
    GlobalContext
  );

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPostingSale, setIsPostingSale] = useState<boolean>(false);
  const [valitorLoaded] = loadScript(paymentProvider?.information?.url || '');
  const [isWaiting, setFetching] = useState<boolean>(true);
  const [valitorSignature, setValitorSignature] = useState<
    ValitorSignatureResponse
  >();

  // making sure ISK don't have decimals, that will break valitor integration for ISK.
  // todo: Move elsewhere or delete
  const totalPrice: number =
    currencyCode === 'ISK' ? Number(totalAmount.toFixed(0)) : totalAmount;

  const getValitorResponseData = async (e: any) => {
    setIsLoading(true);
    setIsPostingSale(true);

    const formData: any = new FormData(e.target);
    const valitorResponse = JSON.parse(formData.get('response'));

    e.preventDefault();

    const salesResponse = await postSale(
      customerInfo.email,
      basket.basketItems,
      basket.externalUid,
      {
        valitorPayResponse: {
          paymentMethod: 4,
          cardType: valitorResponse.CardType,
          authorizationNumber: valitorResponse.AuthorizationNumber,
          transactionNumber: valitorResponse.TransactionNumber,
          agreementNumber: valitorResponse.AgreementNumber
        }
      }
    );

    if (salesResponse.status === 200) {
      goNext({
        paymentStatus: PaymentStatus.Success,
        salesResponse: salesResponse.data
      });
    } else {
      goNext({ paymentStatus: PaymentStatus.Failure });
    }
  };

  // countering a bug in the Valitor iFrame where it's not ready to take input
  setTimeout(() => {
    setFetching(false);
  }, 1000);

  useEffect(() => {
    if (valitorLoaded) {
      (async () => {
        // code to fetch valitor merchant moved to global
        // to battle time sensitivity of the valitor iframe
        if (paymentProvider?.type === 'Valitor') {
          window.valitor.checkout.init({
            key: paymentProvider?.information?.key,
            language: 'en',
            merchant: paymentProvider?.information?.name,
            currency: currencyCode
          });
        }
        const response = await getValitorSignature(totalPrice);
        setValitorSignature(response?.data);

        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })();
    }
  }, [valitorLoaded]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {isPostingSale && <DisableScreenClick />}
          <Loader width={200} color="black" />
        </>
      );
    }

    return (
      <>
        <S.Title>{t('Basket.PaymentMethod')}</S.Title>
        <S.ButtonWrapper>
          <PaymentButton
            label={t('Basket.Card')}
            icon="Card"
            onClicked={async () =>
              window.valitor.checkout.open({
                amount: totalPrice,
                merchantReferenceId: valitorSignature?.merchantReferenceId,
                digitalSignature: valitorSignature?.signature
              })
            }
            loading={isWaiting}
          />
        </S.ButtonWrapper>
      </>
    );
  };

  return (
    <S.Wrapper>
      <form id="valitorCheckout" onSubmit={getValitorResponseData} />
      <S.ContentWrapper>{renderContent()}</S.ContentWrapper>
    </S.Wrapper>
  );
};

export default ValitorPayment;
