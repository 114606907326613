import React, { useContext, useState, useEffect } from 'react';
import * as S from './ApplePayment.styles';
import { PaymentType } from '../../../../../../../../types';
import { getApplePaymentSession } from '../../../../../../../../services/applepay.service';
import GlobalContext from '../../../../../../../../state/GlobalContext';

declare const window: any;

export type ApplePaymentProps = {
  totalAmount?: number;
  goNext: Function;
  goBack: Function;
  finalizeSale: Function;
  handlePaymentMethodChosen: Function;
};

const ApplePayment = ({
  handlePaymentMethodChosen,
  totalAmount,
  goBack,
  finalizeSale
}: ApplePaymentProps) => {
  const { currencyCode, basket, shopName, paymentProvider } = useContext(
    GlobalContext
  );
  const [isApplePaySupported, setIsApplePaySupported] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (
      window &&
      window?.ApplePaySession &&
      window?.ApplePaySession?.canMakePayments()
    ) {
      setIsApplePaySupported(true);
    }
  }, []);

  const paymentRequestData = {
    countryCode: paymentProvider?.information?.merchantCountryCode, // Apple Pay country code (historically 'GB')
    currencyCode,
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
    total: {
      label: shopName,
      type: 'final',
      amount: totalAmount?.toString() || '0'
    },
    lineItems: basket.basketItems.map(i => ({
      label: i.label || '',
      amount: i?.price ? i?.price.toString() : '0'
    }))
  };

  const startPaymentProcess = async () => {
    handlePaymentMethodChosen();
    let request: any;

    if (window.ApplePaySession) {
      try {
        request = new window.ApplePaySession(10, paymentRequestData);
        request.begin();

        request.onvalidatemerchant = async (event: any) => {
          const { host } = window.location;
          const response = await getApplePaymentSession(
            event.validationURL,
            host
          );
          if (response.status < 400) {
            try {
              request.completeMerchantValidation(response?.data);
            } catch (e) {
              goBack();
            }
          }
        };

        request.oncancel = () => {
          goBack();
        };

        request.onpaymentauthorized = (event: any) => {
          const token = event?.payment?.token?.paymentData;
          const authorizationResult = {
            status: window?.ApplePaySession.STATUS_SUCCESS,
            errors: []
          };
          request.completePayment(authorizationResult);
          finalizeSale({
            digitalWallet: {
              amount: totalAmount || 0,
              token: JSON.stringify(token),
              paymentMethod: PaymentType.applePay
            }
          });
        };
      } catch (e) {
        console.error(e);
        goBack();
      }
    }
  };

  if (isApplePaySupported) {
    return <S.ApplePayButton onClick={startPaymentProcess} />;
  }

  return null;
};

export default ApplePayment;
