import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
// eslint-disable-next-line import/no-unresolved
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { Loader } from '@k3imagine/self-serve-components';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { startAdyenSession } from '../../../../../../services/payment.service';
import GlobalContext from '../../../../../../state/GlobalContext';
import {
  AdyenPaymentResultCode,
  AdyenSessionResponse,
  BasketItem,
  PaymentStatus
} from '../../../../../../types';
import { PaymentResultFunction } from '../types';

interface AdyenPaymentProps {
  goNext: PaymentResultFunction;
}

interface CheckoutProps {
  basketId: string;
  items: BasketItem[];
  email?: string;
  goNext: PaymentResultFunction;
}

interface AdyenCheckoutState {
  isLoading: boolean;
  error: string | null;
  session: AdyenSessionResponse | null;
}

const AdyenPayment = ({ goNext }: AdyenPaymentProps) => {
  const { basket, customerInfo } = useContext(GlobalContext);

  return (
    <Checkout
      basketId={basket.externalUid}
      items={basket.basketItems}
      email={customerInfo.email}
      goNext={goNext}
    />
  );
};

const Checkout = ({ basketId, items, email, goNext }: CheckoutProps) => {
  const paymentContainer = useRef<HTMLDivElement>(null);
  const [adyen, setAdyenState] = useState<AdyenCheckoutState>({
    isLoading: true,
    error: null,
    session: null
  });

  // get new session if basket ID changes
  useEffect(() => {
    if (adyen.session !== null) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    startAdyenSession(basketId, email, cancelTokenSource.token)
      .then(({ status, data }) => {
        if (status !== 200 || 'error' in data) {
          let errorMessage = 'Failed to initialise payment session';
          if ('error' in data) errorMessage += `: ${data.error}`;

          throw new Error(errorMessage);
        }

        setAdyenState({ isLoading: false, error: null, session: data });
      })
      .catch(e => {
        if (axios.isCancel(e)) {
          return;
        }

        setAdyenState({ isLoading: false, error: e.message, session: null });
      });

    return () => {
      cancelTokenSource.cancel('component has been unmounted');
    };
  }, [basketId, email]);

  useEffect(() => {
    if (!adyen || !adyen?.session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    let element$: InstanceType<PaymentMethods['dropin']> | null = null;

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        environment: adyen.session?.environment,
        clientKey: adyen.session?.clientKey,
        session: adyen.session?.session,
        paymentMethodsConfiguration: adyen.session?.paymentMethodsConfig,
        onPaymentCompleted: async (response: any) => {
          const isSuccess = [
            AdyenPaymentResultCode.Authorised,
            AdyenPaymentResultCode.Received,
            AdyenPaymentResultCode.Pending
          ].includes(response.resultCode);
          if (!isSuccess) {
            await goNext({ paymentStatus: PaymentStatus.Failure });
            return;
          }

          await goNext({ paymentStatus: PaymentStatus.Success });
        },
        onError: async () => {
          await goNext({ paymentStatus: PaymentStatus.Failure });
        }
      });

      if (paymentContainer.current) {
        element$ = checkout.create('dropin', {
          instantPaymentTypes: ['applepay', 'paywithgoogle']
        });
        element$.mount(paymentContainer.current);
      }
    };

    createCheckout();

    return () => {
      element$?.unmount();
    };
  }, [adyen.session?.id]);

  return (
    <>
      {adyen.isLoading && <Loader width={200} color="black" />}
      {!adyen.isLoading && adyen.error ? (
        <div>Failed to initialise payment session</div>
      ) : null}
      <div ref={paymentContainer} className="payment" />
    </>
  );
};

export default AdyenPayment;
