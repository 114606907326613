import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BasketModalContent } from '@k3imagine/self-serve-components';
import GlobalContext from '../../../../state/GlobalContext';
import * as S from './UserInfo.styles';
import { CustomerInfo } from '../../../../types';

type UserInfoProps = {
  goNext: Function;
  goBack: Function;
  customerInfo: CustomerInfo;
  saveCustomerInfo: Function;
};

const UserInfo = ({
  customerInfo,
  saveCustomerInfo,
  goNext,
  goBack
}: UserInfoProps) => {
  const { t } = useTranslation();
  const { visualProfileColors, config, table } = useContext(GlobalContext);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const validateEmail = (email?: string) => {
    if (email) {
      return email.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
      );
    }
    return true;
  };

  const validateInfo = () => {
    const { email } = customerInfo;
    if (email && validateEmail(email)) {
      goNext();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    // eslint-disable-next-line
    (customerInfo as any)[name] = value;
    saveCustomerInfo(customerInfo);
  };

  const { email } = customerInfo;

  return (
    <BasketModalContent
      styles={styles}
      title={t('Customer.Customer')}
      subTitle={`${
        config?.orderReferenceCaption
          ? `${config.orderReferenceCaption}: `
          : 'Table '
      }${table.name}`}
      buttonLabel={t('Continue')}
      buttonIcon="BackArrow"
      onIconButtonClicked={() => goBack()}
      onButtonClicked={() => validateInfo()}
    >
      <S.Wrapper>
        <S.Title>{t('Customer.PleaseFillInCustomerInformation')}</S.Title>
        <S.InputWrapper>
          <div id="component-container" />
          <S.InputItem>
            <S.Label error={!validateEmail(email)}>
              {t('Customer.Email')}
            </S.Label>
            <S.Input
              type="email"
              name="email"
              defaultValue={email}
              onChange={handleChange}
              error={!validateEmail(email)}
            />
            {!validateEmail(email) && (
              <S.Error>{t('Customer.EmailNotValid')}</S.Error>
            )}
          </S.InputItem>
        </S.InputWrapper>
      </S.Wrapper>
    </BasketModalContent>
  );
};

export default UserInfo;
