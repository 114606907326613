import { HistoryLocation } from '@reach/router';
import React, { useState, useEffect, useContext, createContext } from 'react';
import { navigate } from 'gatsby';
import { Layout as LayoutComponent } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../state/GlobalContext';
import { BasketModalWizard, WizardSteps } from '../BasketModalWizard';
import { useDebounce } from '../../state/useDebounce';
import { augmentUrlRoute } from '../../utils/urlUtils';
import { GridItemProps, OrderCustomization, Breadcrumb } from '../../types';
import { ProductDetailModal, InformationModal } from '..';

export const LayoutContext = createContext<{
  stripeSuccess: boolean;
  search: string;
  setSearch: Function;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Function;
  showProductDetailModal: boolean;
  productDetailModalInfo?: {
    product: GridItemProps & { customization?: OrderCustomization };
    isNew: boolean;
  };

  setShowProductDetailModal: Function;
  setProductDetailModalInfo: Function;
  showBasketModal: boolean;
  setShowBasketModal: Function;
}>({
  search: '',
  setSearch: () => {},
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  showProductDetailModal: false,
  setShowProductDetailModal: () => {},
  setProductDetailModalInfo: () => {},
  showBasketModal: false,
  setShowBasketModal: () => {}
});

export default ({
  children,
  location
}: {
  children: any;
  location: HistoryLocation;
}) => {
  const initialState = {
    showBasketModal: false,
    wizardStep: WizardSteps.BasketOverview
  };

  // Check if Stripe Redirect
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('stripeSuccess') || queryParams.has('stripeFailed')) {
    initialState.showBasketModal = true;
    initialState.wizardStep = WizardSteps.Payment;
  }

  if (
    location.state?.hasOwnProperty('redirectTo') &&
    location.state.redirectTo === 'payment-summary'
  ) {
    initialState.showBasketModal = true;
    initialState.wizardStep = WizardSteps.PaymentStatus;
  }

  const [showBasketModal, setShowBasketModal] = useState<boolean>(
    initialState.showBasketModal
  );
  const [showProductDetailModal, setShowProductDetailModal] = useState<boolean>(
    false
  );
  const [showInformationModal, setShowInformationModal] = useState<boolean>(
    false
  );
  const [productDetailModalInfo, setProductDetailModalInfo] = useState<{
    product: GridItemProps;
    isNew: boolean;
  }>();
  const [search, setSearch] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const {
    basket,
    imageUrl,
    config,
    table,
    visualProfileColors,
    rootComposerGroup
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    basketButton: {
      background: visualProfileColors?.button?.primary?.background
    }
  };

  const hasShopPolicies =
    config?.faqEnabled ||
    config?.termsAndCondEnabled ||
    config?.deliveryPolicyEnabled ||
    config?.returnsPolicyEnabled;

  if (typeof window !== 'undefined' && window && location.pathname === '/') {
    navigate(augmentUrlRoute(`/groups/${rootComposerGroup?.groupUrlName}`));
  }

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  useEffect(() => {
    // When the modal is shown, we want a fixed body so that the application does not scroll in the background
    document.body.style.overflow =
      showBasketModal || showProductDetailModal || showInformationModal
        ? 'hidden'
        : 'initial';
  }, [showBasketModal, showProductDetailModal, showInformationModal]);

  const breadcrumbsClicked = (breadcrumb: Breadcrumb) => {
    setSearch('');
    const breadcrumbIndex = breadcrumbs.indexOf(breadcrumb);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
    setBreadcrumbs(newBreadcrumbs);
    navigate(augmentUrlRoute(`/groups/${groupPath}`));
  };

  const goToRootGroup = () => {
    setSearch('');
    navigate(augmentUrlRoute(`/groups/${rootComposerGroup?.groupUrlName}`));
  };

  const getOrderReferenceString = (): string => {
    return `${
      config?.orderReferenceCaption
        ? `${config.orderReferenceCaption}: `
        : 'Table '
    }${table.name}`;
  };

  const onShowInformationModal = () => {
    setShowInformationModal(true);
  };

  return (
    <>
      <LayoutContext.Provider
        value={{
          search: useDebounce(300, search),
          setSearch,
          breadcrumbs,
          setBreadcrumbs,
          showProductDetailModal,
          setShowProductDetailModal,
          productDetailModalInfo,
          setProductDetailModalInfo,
          showBasketModal,
          setShowBasketModal
        }}
      >
        <LayoutComponent
          styles={styles}
          searchPlaceholder={t('Search')}
          searchValue={search}
          logoUrl={imageUrl}
          breadcrumbs={breadcrumbs}
          orderReference={getOrderReferenceString()}
          onBreadcrumbClicked={breadcrumbsClicked}
          onLogoClicked={goToRootGroup}
          onSearchClicked={handleSearch}
          onInfoClicked={hasShopPolicies && onShowInformationModal}
          onBasketClicked={() => {
            setShowBasketModal(true);
          }}
          basketQuantity={basket.totalQuantity}
        >
          {children}
        </LayoutComponent>
        <BasketModalWizard
          step={initialState.wizardStep}
          showModal={showBasketModal}
          handleShowModal={(showModalValue: boolean) =>
            setShowBasketModal(showModalValue)
          }
        />
        <ProductDetailModal />
        <InformationModal
          showModal={showInformationModal}
          onModalClosed={() => setShowInformationModal(false)}
        />
      </LayoutContext.Provider>
    </>
  );
};
