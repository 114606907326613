import api from '../utils/api';
import { Item } from '../types';

export const getItemById = async (itemId: number): Promise<Item> => {
  try {
    const { data } = await api.get(`/item/${itemId}`);
    return data;
  } catch (e) {
    return e.response;
  }
};
