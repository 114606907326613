import React, { useContext } from 'react';
import {
  BasketList,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import GlobalContext from '../../../../state/GlobalContext';
import { LayoutContext } from '../../../Layout/Layout';
import {
  GridItemProps,
  BasketItem,
  NumberPickerActionType
} from '../../../../types/basket.type';

type BasketOverviewProps = {
  goNext: Function;
  closeModal: Function;
};

const BasketOverview = ({ goNext, closeModal }: BasketOverviewProps) => {
  const {
    basket,
    currencyCode,
    removeBasketItem,
    updateBasketItem,
    visualProfileColors,
    clearBasket,
    config,
    defaultLocale,
    composerTiles,
    basketLoading
  } = useContext(GlobalContext);
  const { setShowProductDetailModal, setProductDetailModalInfo } = useContext(
    LayoutContext
  );
  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const priceSummary = [
    { id: 1, label: t('Basket.Total'), value: basket.totalPrice, bold: true }
  ];

  const emptyBasket = () => {
    clearBasket();
    closeModal();
  };

  const handleNumberPickerChange = ({
    actionType,
    item
  }: {
    actionType: NumberPickerActionType;
    item: BasketItem;
  }) => {
    if (actionType === '+') {
      item.quantity += 1;
      debouncehHandler(item);
    }
    if (actionType === '-') {
      if (item.quantity && item.quantity <= 1) {
        removeBasketItem(item.id);
      } else {
        item.quantity -= 1;
        debouncehHandler(item);
      }
    }
  };

  const debouncehHandler = debounce((info: any) => {
    updateBasketItem(info.id, info.quantity, info.customization);
  }, 1000);

  const mapBasketItems = (basketItems: BasketItem[]) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems.map(item => {
        const tilesInfo = composerTiles.find(
          tile =>
            tile.referenceId === item.referenceId && tile.type === item.type
        );
        const newItem = { ...tilesInfo, ...item };
        return newItem;
      });
    }
    return [];
  };
  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Basket')}
      subTitle={
        config?.orderReferenceCaption ? config.orderReferenceCaption : 'Table '
      }
      buttonLabel={t('Continue')}
      buttonDisabled={
        (basket.basketItems && basket.basketItems.length <= 0) || basketLoading
      }
      buttonIcon="Trash"
      onIconButtonClicked={() =>
        basket.totalQuantity > 0 ? emptyBasket() : null
      }
      onButtonClicked={() =>
        basket.totalQuantity > 0 && !basketLoading ? goNext() : null
      }
    >
      <BasketList
        emptyListText={t('Basket.NoItems')}
        basketItems={mapBasketItems(basket.basketItems)}
        onNumberPickerChanged={(info: {
          actionType: NumberPickerActionType;
          item: BasketItem;
        }) => handleNumberPickerChange(info)}
        onRemoveClicked={({ item }: { item: GridItemProps }) => {
          removeBasketItem(item.id);
        }}
        onEditClicked={({ item }: { item: GridItemProps }) => {
          setProductDetailModalInfo({ product: item, isNew: false });
          setShowProductDetailModal(true);
        }}
        onRowClicked={({ item }: { item: GridItemProps }) => {
          setProductDetailModalInfo({ product: item, isNew: false });
          setShowProductDetailModal(true);
        }}
        summaryListItems={priceSummary}
        currencyCode={currencyCode}
        locale={defaultLocale}
      />
    </BasketModalContent>
  );
};

export default BasketOverview;
