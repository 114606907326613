import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BasketList } from '@k3imagine/self-serve-components';

import * as S from '../BasketValidation.styles';
import GlobalContext from '../../../../../state/GlobalContext';
import { BasketItem } from '../../../../../types';

type SomeBasketItemsNotAvailableProps = {
  totalAmount: number;
  availableBasketItems: BasketItem[];
  unavailableBasketItems: BasketItem[];
};

const SomeBasketItemsNotAvailable = ({
  totalAmount,
  availableBasketItems,
  unavailableBasketItems
}: SomeBasketItemsNotAvailableProps) => {
  const { currencyCode, defaultLocale } = useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <>
      <S.Title>{t('Error.Title')}</S.Title>
      <S.Title>
        {t('BasketValidation.SomeItemsInYourBasketHaveSoldOut')}
      </S.Title>
      <S.SubTitle>
        {t('BasketValidation.TheseAreTheItemsNotAvailable')}
      </S.SubTitle>

      <S.BasketListWrapper>
        <BasketList
          basketItems={unavailableBasketItems}
          currencyCode={currencyCode}
          locale={defaultLocale}
        />
        <S.NotAvailableOverlay />
      </S.BasketListWrapper>

      <S.SubTitle>
        {t('BasketValidation.HereAreTheItemsStillAvailable')}
      </S.SubTitle>
      <S.BasketListWrapper>
        <BasketList
          basketItems={availableBasketItems}
          currencyCode={currencyCode}
          locale={defaultLocale}
          summaryListItems={[
            {
              id: 0,
              label: t('Basket.Total'),
              value: totalAmount,
              bold: true
            }
          ]}
        />
      </S.BasketListWrapper>
    </>
  );
};

export default SomeBasketItemsNotAvailable;
