import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasketModal, Tabs } from '@k3imagine/self-serve-components';
import { InformationTab } from './InformationModal.types';

import * as S from './InformationModal.styles';
import GlobalContext from '../../state/GlobalContext';

const InformationModal = ({
  showModal,
  onModalClosed = () => {}
}: {
  showModal: boolean;
  onModalClosed?: Function;
}) => {
  const [tabs, setTabs] = useState<InformationTab[]>();
  const [currentTab, setCurrentTab] = useState<InformationTab>();

  const { config } = useContext(GlobalContext);

  const { t } = useTranslation();

  const getTabs = () => {
    let newTabs: InformationTab[] = [];

    if (config?.faqEnabled) {
      newTabs = [
        ...newTabs,
        { id: 1, label: t('InformationModal.FAQ'), value: config.faqText }
      ];
    }
    if (config?.termsAndCondEnabled) {
      newTabs = [
        ...newTabs,
        {
          id: 2,
          label: t('InformationModal.T&Cs'),
          value: config.termsAndCondText
        }
      ];
    }
    if (config?.deliveryPolicyEnabled) {
      newTabs = [
        ...newTabs,
        {
          id: 3,
          label: t('InformationModal.DeliveryPolicy'),
          value: config.deliveryPolicyText
        }
      ];
    }
    if (config?.returnsPolicyEnabled) {
      newTabs = [
        ...newTabs,
        {
          id: 4,
          label: t('InformationModal.ReturnPolicy'),
          value: config.returnsPolicyText
        }
      ];
    }

    return newTabs;
  };

  useEffect(() => {
    setTabs(getTabs());
  }, []);

  useEffect(() => {
    if (tabs) {
      setCurrentTab(tabs[0]);
    }
  }, [showModal]);

  return (
    <BasketModal
      title={t('InformationModal.ShopInformation')}
      onCloseClicked={() => onModalClosed()}
      isHidden={!showModal}
    >
      <Tabs
        selectedTab={currentTab}
        tabs={tabs}
        onChanged={({
          selectedTab
        }: {
          selectedTab: { id: number; label: string };
        }) => setCurrentTab(selectedTab)}
      >
        <S.Text dangerouslySetInnerHTML={{ __html: currentTab?.value || '' }} />
      </Tabs>
    </BasketModal>
  );
};

export default InformationModal;
