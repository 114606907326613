import React from 'react';
import { Error } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Error
      title={t('Error.Title')}
      message={t('Error.Message')}
      icon="Spaceship"
    />
  );
};

export default ErrorPage;
