import React, { useContext, useState, useEffect } from 'react';
import { Loader } from '@k3imagine/self-serve-components';
import { PaymentExtensions } from '@k3imagine/imagine-extension-framework/dist';

import { PaymentExtensionResult } from '@k3imagine/imagine-extension-framework/dist/models/paymentExtensionResult';
import * as S from './PaymentExtension.styles';
import GlobalContext from '../../../../../../state/GlobalContext';
import { PaymentStatus } from '../../../../../../types';
import { postSale } from '../../../../../../services/payment.service';
import { DisableScreenClick } from '../../Payment.styles';
import { urlParams } from '../../../../../../utils/urlUtils';

type PaymentProps = {
  goNext: Function;
};

const PaymentExtension = ({ goNext }: PaymentProps) => {
  const [iFrameHeight] = useState<string>('700px');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPostingSale, setIsPostingSale] = useState<boolean>(false);

  const { customerInfo, basket, shopId } = useContext(GlobalContext);
  const paymentExtensionIframeRef = React.useRef<HTMLIFrameElement>(null);
  const paymentExtensions: PaymentExtensions = new PaymentExtensions(
    Number(shopId),
    basket.externalUid,
    `${process.env.API_URL}/api/${process.env.API_VERSION}`,
    undefined,
    undefined,
    {
      TenantId: urlParams.tenantId,
      ShopId: urlParams.shopId,
      TableId: urlParams.tableId
    }
  );

  useEffect(() => {
    (async () => {
      const paymentExtensionResult: PaymentExtensionResult = await paymentExtensions.initiatePayment(
        paymentExtensionIframeRef.current
      );
      setIsLoading(true);
      setIsPostingSale(true);
      if (!paymentExtensionResult.success) {
        goNext({ paymentStatus: PaymentStatus.Failure });
      } else {
        try {
          const response = await postSale(
            customerInfo.email,
            basket.basketItems,
            basket.externalUid
          );
          if (response.status < 400) {
            goNext({
              paymentStatus: PaymentStatus.Success,
              salesResponse: response.data
            });
          } else {
            goNext({ paymentStatus: PaymentStatus.Failure });
          }
        } catch (error) {
          goNext({ paymentStatus: PaymentStatus.Failure });
        }
      }
    })();
  }, []); // Empty dependency array makes useEffect only run once

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.Wrapper>
          <S.TopMargin>
            {isPostingSale && <DisableScreenClick />}
            <Loader width={200} color="black" />
          </S.TopMargin>
        </S.Wrapper>
      );
    }
    return (
      <>
        <S.PaymentExtensionIframe
          ref={paymentExtensionIframeRef}
          id="paymentExtensionIframe"
          height={iFrameHeight}
        />
      </>
    );
  };

  return renderContent();
};

export default PaymentExtension;
