import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  margin-top: 20%;
`;

export const ContentWrapper = styled.div`
  height: 100%;
`;

export const ExtensionIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
