import styled from 'styled-components';

export type StyleProps = {
  error?: boolean;
  valid?: boolean;
  focus?: boolean;
  color?: string;
};

export const InputWrapper = styled.div`
  margin: auto 1em;
`;

export const InputItem = styled.div`
  margin: 16px;
  text-align: left;
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-weight: 200;
`;

export const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
`;

export const PaymentWrapper = styled.div`
  margin: auto 1em;
`;

type MutationConfig = {
  attributes: boolean;
  childList: boolean;
  subtree: boolean;
};

export const Label = styled.label<StyleProps>`
  align-content: center;
  font-size: 0.81em;
  font-weight: 400;
  line-height: 13px;
  padding-bottom: 5px;
  letter-spacing: 0.1em;
  color: ${({ error, focus }) => {
    if (focus) {
      return '#06f';
    }
    if (error) {
      return '#d10244';
    }
    return 'black';
  }};
`;

export const Input = styled.input<StyleProps>`
  color: #00112c;
  caret-color: #06f;
  font-size: 1em;
  font-family: inherit;
  display: block;
  height: 40px;
  background: #fff;
  border: 1px solid ${({ error }) => (error ? '#d10244' : '#b9c4c9')};
  border-radius: 6px;
  padding: 5px 8px;
  position: relative;
  outline: none;
  width: calc(100% - 16px);
  -webkit-appearance: none;
  height: 28px;
  ::placeholder {
    color: #b9c4c9;
    font-weight: 200;
  }
  :focus {
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
    border-color: #06f;
  }

  -webkit-transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
`;

export const EmailWrapper = styled.div`
  margin-top: 8px;
`;

export const IconWrapper = styled.span<StyleProps>`
  position: absolute;
  width: 16px;
  height: 16px;
  fill: ${({ color }) => color};
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 14px;
`;

export const Error = styled.div<StyleProps>`
  display: flex;
  color: #d10244;
  font-weight: 400;
  margin-top: 4px;
  font-size: 0.75em;
  align-items: center;
`;

export const InnerWrapper = styled.span<StyleProps>`
  position: relative;
  display: block;
`;
