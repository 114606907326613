import {
  BasketModalContent,
  PriceSummaryList
} from '@k3imagine/self-serve-components';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../state/GlobalContext';
import * as S from './Payment.styles';
import {
  AdyenPayment,
  OBPayPayment,
  PaymentExtension,
  ValitorPayment
} from './PaymentProviders';
import { PaymentResultFunction } from './PaymentProviders/types';
import StripePayment from './PaymentProviders/StripePayment/StripePayment';

type PaymentProps = {
  goNext: PaymentResultFunction;
  goBack: Function;
  totalAmount: number;
};

const Payment = ({ goNext, goBack, totalAmount }: PaymentProps) => {
  const [priceSummary, setPriceSummary] = useState<
    { id: any; label: string; value: any; bold: boolean }[]
  >([]);
  const {
    paymentProvider,
    config,
    table,
    visualProfileColors,
    currencyCode
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  useEffect(() => {
    setPriceSummary([
      {
        id: 0,
        label: t('Basket.TotalToPay'),
        value: totalAmount,
        bold: true
      }
    ]);
  }, []);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const renderPaymentProvider = () => {
    switch (paymentProvider?.type) {
      case 'Adyen':
        return <AdyenPayment goNext={goNext} />;
      case 'Stripe':
        return <StripePayment goNext={goNext} table={table} />;
      case 'Valitor':
        return <ValitorPayment goNext={goNext} totalAmount={totalAmount} />;
      case 'GlobalPay':
        return <OBPayPayment goNext={goNext} totalAmount={totalAmount} />;
      case 'PaymentExtension':
        return <PaymentExtension goNext={goNext} />;
      default:
        return (
          <>
            <S.ErrorTitle>{t('Error.Title')}</S.ErrorTitle>
            <S.ErrorSubTitle>{t('Error.Message')}</S.ErrorSubTitle>
          </>
        );
    }
  };

  const renderContent = () => {
    return (
      <S.Wrapper>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        {renderPaymentProvider()}
      </S.Wrapper>
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Payment')}
      subTitle={`${
        config?.orderReferenceCaption
          ? `${config.orderReferenceCaption}: `
          : 'Table '
      }${table.name}`}
      onIconButtonClicked={goBack}
      buttonIcon="BackArrow"
    >
      {renderContent()}
    </BasketModalContent>
  );
};

export default Payment;
