import React, { useContext, useState, ReactNode } from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';

import GlobalContext from '../../state/GlobalContext';

type LoaderProps = {
  loaded: boolean;
};

export const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
`;

const LoaderWrapper = styled.div<LoaderProps>`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: #fff;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  ${({ loaded }) =>
    loaded &&
    css`
      opacity: 1;
      animation: ${fadeOut} 1s ease-in-out;
    `}
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;
const Logo = styled.img<LoaderProps>`
  opacity: 0;
  width: 90%;
  height: 100%;
  object-fit: contain;
  ${({ loaded }) =>
    loaded &&
    css`
      opacity: 1;
      animation: ${opacity} 1s ease-in-out;
    `}
  animation-direction: alternate;
`;

export const StartScreen = ({ children }: { children: ReactNode | null }) => {
  const { imageUrl, error, loading } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState(false);

  if (error) {
    // eslint-disable-next-line
    console.error(error);
    navigate('/error');
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <LoaderWrapper loaded={loaded}>
        <Logo
          src={imageUrl}
          alt="Shop Logo"
          loaded={loaded}
          onLoad={() => setLoaded(true)}
        />
      </LoaderWrapper>
      {loaded && children}
    </>
  );
};

export default StartScreen;
