import React, { useContext, useState } from 'react';
import { Loader, PaymentButton } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import * as S from './OBPayPayment.styles';
import { CardPayment, GooglePayment, ApplePayment } from './PaymentMethods';
import GlobalContext from '../../../../../../state/GlobalContext';
import {
  DigitalWalletResponseInfo,
  PaymentStatus
} from '../../../../../../types';
import { postSale } from '../../../../../../services/payment.service';
import { DisableScreenClick } from '../../Payment.styles';
import { PaymentMethod } from './OBPayPayment.types';

type PaymentProps = {
  totalAmount: number;
  goNext: Function;
};

const OBPayPayment = ({ goNext, totalAmount }: PaymentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();

  const { t } = useTranslation();
  const { customerInfo, basket, paymentProvider } = useContext(GlobalContext);

  const finalizeSale = async (paymentResponse: {
    globalPayResponse: any;
    digitalWallet?: DigitalWalletResponseInfo;
  }) => {
    setIsLoading(true);

    const response = await postSale(
      customerInfo.email,
      basket.basketItems,
      basket.externalUid,
      paymentResponse
    );
    if (response.status < 400) {
      goNext({
        paymentStatus: PaymentStatus.Success,
        salesResponse: response.data
      });
    } else {
      goNext({ paymentStatus: PaymentStatus.Failure });
    }
  };

  const renderPaymentMethodContent = () => {
    switch (paymentMethod) {
      case PaymentMethod.Card:
        return (
          <CardPayment
            goNext={goNext}
            finalizeSale={finalizeSale}
            totalAmount={totalAmount}
          />
        );
      case PaymentMethod.ApplePay:
      case PaymentMethod.GooglePay:
        return (
          <>
            <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
            <Loader width={200} color="black" />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <DisableScreenClick />
          <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
          <Loader width={200} color="black" />
        </>
      );
    }

    if (!paymentMethod) {
      return (
        <>
          <S.Title>{t('Basket.HowWouldYouLikeToFinishYourPayment')}</S.Title>
          <S.ButtonWrapper>
            <PaymentButton
              icon="Card"
              label="Card"
              theme="Dark"
              onClicked={() => setPaymentMethod(PaymentMethod.Card)}
            />
            {paymentProvider?.information?.enableApplePay && (
              <ApplePayment
                goNext={goNext}
                goBack={() => setPaymentMethod(undefined)}
                finalizeSale={finalizeSale}
                totalAmount={totalAmount}
                handlePaymentMethodChosen={() =>
                  setPaymentMethod(PaymentMethod.ApplePay)
                }
              />
            )}
            {paymentProvider?.information?.enableGooglePay && (
              <GooglePayment
                goNext={goNext}
                goBack={() => setPaymentMethod(undefined)}
                finalizeSale={finalizeSale}
                totalAmount={totalAmount}
                handlePaymentMethodChosen={() =>
                  setPaymentMethod(PaymentMethod.GooglePay)
                }
              />
            )}
          </S.ButtonWrapper>
        </>
      );
    }

    return <>{renderPaymentMethodContent()}</>;
  };

  return (
    <S.Wrapper>
      <S.ContentWrapper>{renderContent()}</S.ContentWrapper>
    </S.Wrapper>
  );
};

export default OBPayPayment;
